export default {
    namespaced: true,
    state: () => ({
        api:{
            edmame:{key:null,id:null},
            fs:{key:null,id:null}
          },
    }),
    getters: {
/*         api: (state, name) => {
            return state.api[name];
          }, */
          api: (state) => (name) => {
            return state.api[name];
          }
     // getItem: state => state.item
    },
    mutations: {
        set(state, data) {
            console.log(data)
            state.api[data.api][data.name] = data.val 
            console.log(state.api)
            //{api:'edmame', name:'key', val: value}
            //state.user.name = name;
          },
    },
    actions: {
        setName({ commit }, newValue) {
            console.log(newValue)
            console.log('set')
            commit("set", newValue);
          },
    },
  };