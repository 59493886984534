
import Hammer from 'hammerjs'

var mixin = {
  data() {
    return {
    };
  },
  created: function () {
    // console.log("mixin")
    //this.hello()
  },
  methods: {

    addFood(apiData) {
      console.log('tgrfedgtyt')
      if(apiData){
        this.$store.commit('addFood', apiData)
        this.$store.commit('toggleNotification', {text:'Added',toggle: true})

      }
      else {
        this.newFood.id = Date.now()
        this.$store.commit('addFood', this.newFood)
        this.$store.commit('toggleNotification', {text:'Added',toggle: true})

      }
      this.newFood = {
             id: '',
            name: '',
            caloriesPer100g: '',
            nutrients:'',
            image: '',
            category:'',
            measure: '',
            calculatedCals:'' ,
            knownAs:"",
            grams: "",
            brand:''
      }
    },
    removeFood(food) {
      this.$store.commit('removeFood', food)
    },
    updateFood(food) {
  
      this.$store.commit('updateFood', food)
      this.$store.commit('toggleNotification', {text:'Updated',toggle: true})

    },

    buttonSwipe(){
      var el = document.querySelectorAll('.FoodCard')
      el.forEach(element => {
        let hammer = new Hammer(element);
        hammer.on('swipeleft', function (ev) {
          console.log(ev);
          var button = element.querySelector('.hiddenButtons')
          var right = element.querySelector('.number-input')

         // right.style.marginRight = '6em';
          button.style.transform = "translateX(0px)";
          //element.style.width = "40%";

        });

        hammer.on('swiperight', function (ev) {
          var button = element.querySelector('.hiddenButtons')
          var right = element.querySelector('.number-input')
          console.log(button)
          //button.addClass()
          // button[0].classList.remove("reveal");
          //element.style.width = "100%";
          right.style.marginRight = '0em';
          button.style.transform = "translateX(170px)";
          /* element.style.right = "0px";
          element.style.position = ""; */


        });


      })
    }
  },

}

export default mixin

/* default {
    computed: {
        currentUser() {
            return window.currentUser;
        },
    },
}; */