import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/app.css'
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
Vue.config.productionTip = false

import mixin from '@/mixins.js';
import paneMixin from '@/mixins/panes.js';

Vue.mixin(mixin); 
Vue.mixin(paneMixin); 

Vue.prototype.$panes = Vue.observable({});
//Vue.prototype.$dict = Vue.observable( {});


/* Vue.prototype.$sortable = Vue.observable([
  
]); */

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
