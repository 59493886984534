<template>
  <div id="app">
 
    <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
      <BottomPane :id="'add'">  <addItem></addItem></BottomPane>
      <Notification v-if="$store.state.currentNotification.toggle === true"></Notification>

      <TabBar></TabBar>
     
  <!-- <router-view/> -->
  </div>
</template>

<script>
import EditFood from './components/Panes/EditFood.vue';


export default {
  name: "app",
  mounted() {
    window.addEventListener("scroll", (e) => {
  e.preventDefault();
  window.scrollTo(0, 0);
});
  },

  updated() {
 console.log("updated")
 window.addEventListener("scroll", (e) => {
  e.preventDefault();
  window.scrollTo(0, 0);
});},
components: {
    TabBar: () => import("@/components/TabBar.vue"),
    BottomPane: () => import("@/components/BottomPane.vue"),
    addItem: () => import("@/components/Panes/AddItem.vue"),
    counter: () => import("@/components/Panes/CounterPane.vue"),
    Notification: () => import("@/components/Notification.vue"),
    EditFood
},
}
</script>

<style lang="scss">
body,html {
  overscroll-behavior: none;
  //var(--font);
}

body {
  //position: fixed;
  background:grey;
  width: 100%;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  height: 99.9vh;
  height: 100vh;
  overflow: hidden;
}

#app {
 // font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-family: var(--font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--gray-50);
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  overscroll-behavior: none;
overflow: hidden;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}


</style>
