import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';


export default {
  namespaced: true,
  state: () => ({
    elements:{
/*       addPane:false,
      editorPane:false */
    }
  }),
  getters: {
   // getItem: state => state.item
  },
  mutations: {
    setPane(state, data) {
      console.log(data)
    //  state.elements[data.id] = data.val;
      state.elements[data.id] = new CupertinoPane(
        '#' + data.id + '.BottomPane', // Pane container selector
        {
          parentElement: '#app', // Parent container
        }
      );
      state.elements[data.id].present({ animate: true }).then(res => { });
      console.log(state.elements)

    },
/*     toggle(state, val) {
      if(val.opt === "toggle"){
        state.toggle[val.key] =  !state.toggle[val.key]  
      } else {
        state.toggle[val.key] = val.opt;
      }
    } */
  },
  actions: {
  },
};
