import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';

var mixin = {
    created: function () {
        // console.log("mixin")
        //this.hello()
    },
    methods: {
        setPane(id) {
            this.$panes[id] = new CupertinoPane('#' + id + '.BottomPane',
                {
                    parentElement: '#app', 
/*                     breaks: {
                        top: {enabled: true, height: 700},
                        middle: { enabled: true, height: 300 },
                        bottom: { enabled: true, height: 80 },
                    }, */
                }
            );
            this.$panes[id].on('onDidDismiss', () => {
                console.log('dismiss', this.id)
            });
        },

        showPane(id,animate) {
            console.log(id)
            console.log(this.$panes)
            this.$panes[id].present({
                animate: animate,
            }).then(res => { });
        },
        hidePane(id) {
            console.log(id)
            console.log(this.$panes)
            this.$panes[id].hide({ animate:false}).then(res => { });
        },
        fullscreenPane(id) {
            // myPane.moveToBreak('top');
            this.$panes[id].moveToBreak('top');



        }
    },

}

export default mixin