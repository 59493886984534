<template>
  <div class="home view">
   <button @click="showPane('edit')"> edit pane
  </button>
  <router-link to="/settings"> settings </router-link>

  7311440600396
  <!-- <slider :items="$store.state.foods"></slider> -->
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  mounted(){



  },
  components: {
    slider: () => import("@/components/Slider.vue"),
  }
}
</script>
