import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import  store  from '../store' 

Vue.use(VueRouter)

/* {
  path: '/FoodDB',
  name: 'FoodDB',
  component: function () {
    return import('../views/FoodDB.vue')
  }
}, */
const routes = [
  {
    path: '/settings',
    name: 'settings',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Settings.vue')
    }
    
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  
  {
    path: '/recipes',
    name: 'Recipes',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Recipes.vue')
    }
    
  },
  {
    path: '/foodDB',
    name: 'foodDB',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/FoodDB.vue')
    }
    
  }
]

const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored
  next()
}

const router = new VueRouter({
  routes
})
router.beforeEach(waitForStorageToBeReady)

export default router
