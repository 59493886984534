

import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import localforage from "localforage";
import panes from '@/store/modules/panes.js';
import auth from '@/store/modules/auth.js';
Vue.use(Vuex)

// Use localforage as the storage engine for vuex-persist
const vuexPersist = new VuexPersist({
  storage: localforage,
  asyncStorage: true
});



export default new Vuex.Store({
  state: {

    recipes: [],
    foods: [],
    currentNotification: {
      text:null,
      toggle:false
    }
  },
  getters: {
    getFoodList: async (state) => {
      return state.foods;
    }
  },
  mutations: {
    toggleNotification(state, data){
      state.currentNotification.text = data.text;
      state.currentNotification.toggle = data.toggle;
      console.log(state.currentNotification)
    },
    addFood(state, food) {
      if(state.foods.findIndex((f) => f.id === food.id))
      {
        console.log('duplicate')
        food.id = food.id+ '_'+food.name.replace(' ','-').toLowerCase()
        state.foods.push(food)
      }
      else {
        state.foods.push(food)
      }
    },
    clearGrams(state) {
      state.foods.forEach(function (element, index) {
        state.foods[index].grams = '';   //set the value  
      });
    },
    removeFood(state, food) {
      state.foods = state.foods.filter((f) => f.id !== food.id)
    },
    updateFood(state, food) {
      console.log(food)
      const index = state.foods.findIndex((f) => f.id === food.id)
      state.foods[index] = food
      console.log("uodate")
    },
    addRecipe(state, recipe) {
      state.recipes.push(recipe)
    },
    removeRecipe(state, recipe) {
      state.recipes = state.recipes.filter((f) => f.name !== recipe.name)
      console.log(state.recipes)
    },
    updateRecipe(state, recipe) {
      const index = state.recipes.findIndex((f) => f.name === recipe.name)
      state.recipes[index] = recipe
      console.log( state.recipes)

      console.log("uodate")
    },
  },
  actions: {
  },
  modules: {
    panes: panes,
    auth:auth
  },
/*   modules: {
    toggle: toggle,
    food: foodStore,
  },
 */
  plugins: [vuexPersist.plugin]
})



